<template>
  <div>
    <div :class="{ 'content-editor-has-error': hasErrors }">
      <tiptap-vuetify v-model="content" :extensions="extensions" />
    </div>

    <div class="v-text-field__details mt-2 ml-3" v-if="errors.length">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div
            v-for="error in errors"
            :key="`content-errors-${error}`"
            class="v-messages__message"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // starting editor's content
      content: this.value,
    }
  },
  computed: {
    hasErrors() {
      return !!this.errors.length
    },
  },
  watch: {
    value(v) {
      this.content = v
    },
    content(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style>
.content-editor-has-error {
  position: relative;
  border: 2px solid #fa4856;
}
</style>
