<template>
  <div class="file-uploader">
    <p class="text-tertiary text-base">{{ label }}</p>

    <div class="d-flex align-center">
      <v-responsive :aspect-ratio="1">
        <div class="file-uploader__preview">
          <video
            id="file-uploader-video-preview"
            height="56"
            width="56"
            v-if="accept.includes('video') && (previewUrl || initialPreviewUrl)"
            :src="previewUrl || initialPreviewUrl"
            style="border-radius: 8px; background-color: black"
          ></video>

          <v-img
            v-else-if="previewUrl && !accept.includes('video')"
            :src="previewUrl"
            cover
            aspect-ratio="1"
            class="rounded-lg"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div class="pa-4" v-else>
            <v-img src="@/assets/icons/gallery.svg" />
          </div>
        </div>
      </v-responsive>

      <div
        class="file-uploader__button pa-2 ml-4"
        :style="{
          border: errors ? '2px solid #fa4856' : '',
        }"
      >
        <p class="text-tertiary pa-0 ma-0">
          <a target="_blank" :href="value?.url" v-if="value?.url">
            {{ value?.name }}
          </a>

          <span v-else> {{ value?.name ?? 'Browse from computer' }}</span>
        </p>

        <v-btn color="primary" @click="$refs[`file-${id}`].click()">
          <span class="text-sm">Browse File</span>
        </v-btn>
      </div>

      <input
        :ref="`file-${id}`"
        class="d-none"
        type="file"
        @input="onInput"
        :accept="accept"
      />
    </div>

    <div v-if="accept.includes('video')" class="mt-5">
      <video-player
        v-if="checkIVideoIsProcessing(value)"
        ref="videoPlayer"
        class="vjs-custom-skin"
        :options="playerOptions"
        @ready="onPlayerReady(previewUrl || initialPreviewUrl)"
      >
      </video-player>
      <div v-else class="mt-3">
        <span variant="primary"> Video is still processing </span>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from 'vue-videojs7/src/components/VideoPlayer.vue'

export default {
  name: 'FileUploader',
  components: {
    VideoPlayer,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  props: {
    id: String,
    accept: String,
    initialPreviewUrl: String,
    label: {
      type: String,
      default: () => 'Add Thumbnail',
    },
    value: {
      type: [File, Object],
      default: function () {
        return null
      },
    },
    errors: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      previewUrl: this.initialPreviewUrl ?? '',
      playerOptions: {
        autoplay: false,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
      fileType: this.value?.mime_type,
    }
  },
  methods: {
    onInput(e) {
      if (e.target.files.length === 0) return

      const [file] = e.target.files

      this.$emit('input', file)

      this.previewUrl = URL.createObjectURL(file)

      if (this.accept.includes('video')) {
        this.fileType = file.type.replace(/quicktime/gi, 'mp4')
        this.playVideo(this.previewUrl)
      }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: this.fileType,
        src: source,
      }
      this.player.reset()
      this.player.src(video)
    },

    checkIVideoIsProcessing(video) {
      if (video instanceof File) return true
      if (video?.video_transform_job === null) return true
      return video?.video_transform_job?.progress === 100
    },

    onPlayerReady(url) {
      setTimeout(() => {
        this.playVideo(url)
      }, 1000)
    },
  },
}
</script>

<style scoped lang="scss">
.file-uploader {
  &__preview {
    height: 4rem;
    width: 4rem;
    border: 2px dashed rgba(59, 85, 206, 0.8) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  &__button {
    height: 100%;
    border-radius: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid #e6e6e9;
    justify-content: space-between;
  }
}

:deep(.video-js) {
  position: relative;
  width: 100%;
  height: auto;
  .vjs-tech {
    position: relative;
    width: 100%;
    height: 300px;
  }
}
</style>
