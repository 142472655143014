<template>
  <div>
    <v-card
      outlined
      :style="{
        border: errors ? '2px solid #fa4856' : '',
      }"
    >
      <v-card-title class="pt-0">
        <v-row align="center" justify="center">
          <v-col cols="3"><p class="ma-0">Categories</p></v-col>
          <v-col cols="9">
            <v-switch v-model="openAll" label="Expand all"></v-switch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-treeview
        v-if="categories.length !== 0"
        v-model="selection"
        :items="categories"
        selectable
        return-object
        :open-all="openAll"
        :key="treeViewKey"
      />
    </v-card>
    <span v-if="errors" class="error--text ml-3 text-caption">{{
      errors?.[0] ?? errors
    }}</span>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  props: ['items', 'selections', 'errors'],
  data: () => ({
    selection: [],
    openAll: false,
    treeViewKey: 0,
  }),
  watch: {
    selections(value) {
      if (value.length !== 0) {
        this.selection = value
      }
    },
    selection(value) {
      // this.setExerciseCategories(value)
      this.updateSelection(value)
    },
    openAll(value) {
      this.treeViewKey += 1
    },
  },
  methods: {
    ...mapMutations({
      setExerciseCategories: 'exercise/setExerciseCategories',
    }),
    updateSelection(value) {
      this.$emit('updateSelection', value)
    },
  },
  computed: {
    categories() {
      return this.items.map((category) => {
        let item = {
          id: category.id,
          name: category.label,
        }

        if (category.sub_categories) {
          item.children = category.sub_categories.map((subCategory) => {
            let child = {
              id: subCategory.id,
              name: subCategory.label,
            }

            if (subCategory.sub_sub_categories) {
              child.children = subCategory.sub_sub_categories.map(
                (subSubCategory) => {
                  let grandChild = {
                    id: subSubCategory.id,
                    name: subSubCategory.label,
                  }

                  return grandChild
                }
              )
            }

            return child
          })
        }

        return item
      })
    },
    ...mapState('exercise', {
      form: (state) => state.exerciseForm,
    }),
  },
}
</script>

<style lang="scss" scoped>
.category-select {
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  // border-radius: 0.5rem;
}
</style>
