<template>
  <v-dialog
    v-bind:value="value"
    v-on:input="(newValue) => $emit('input', newValue)"
    width="700px"
  >
    <v-card>
      <v-card-title>
        <span class="font-semibold text-2xl">Update Exercise Categories</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <CategorySelectBox
          :items="exerciseCategories"
          :selections="selectedExercises"
          @updateSelection="selectedExercises = $event"
        />
      </v-card-text>
      <v-card-actions>
        <v-col>
          <v-btn
            block
            color="primary"
            large
            :loading="loading"
            @click="$emit('update', selectedExercises)"
          >
            Update
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            block
            class="text-sm"
            color="primary"
            large
            outlined
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CategorySelectBox from '@/components/exercise/CategorySelectBox'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      selectedExercises: [],
    }
  },
  props: {
    value: Boolean,
    loading: Boolean,
  },
  computed: {
    ...mapState({
      exerciseCategories: (state) => state.exerciseCategory.categories,
    }),
  },
  components: {
    CategorySelectBox,
  },
  methods: {
    clearSelectedExercises() {
      this.selectedExercises = []
    },
  },
}
</script>
