<script>
import axios from 'axios'
import router from '@/routes'
import { mapActions, mapState } from 'vuex'
import ContentEditor from '@/components/ContentEditor'
import Form from '@/utils/form'
import { mdiClose } from '@mdi/js'
import Api from '@/services/api'
import FileUploader from '../base/FileUploader.vue'
import ThumbnailUploader from '../base/ThumbnailUploader.vue'
import CategorySelectBox from '@/components/exercise/CategorySelectBox'
import AlternativeExerciseForm from '@/components/exercise/AlternativeExerciseForm'

export default {
  name: 'ExerciseFormModal',
  components: {
    FileUploader,
    ContentEditor,
    ThumbnailUploader,
    CategorySelectBox,
    AlternativeExerciseForm,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState({
      exerciseCategories: (state) => state.exerciseCategory.categories,
      addAltExerciseLoading: (state) => state.exercise.addAltExerciseLoading,
      updateAltExerciseLoading: (state) =>
        state.exercise.updateAltExerciseLoading,
    }),
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      submitting: false,
      form: new Form({
        category_id: null,
        title: null,
        content: null,
        video_id: null,
        thumb_nail_id: null,
        is_free: false,
        video: null,
        thumbnail: null,
        categories: [],
        alternative_exercises: [],
        video_from_s3: null,
        thumb_nail_from_s3: null,
      }),
      showAltExerciseForm: false,
      altExerciseForm: new Form({
        title: null,
        link: null,
        thumb_nail: null,
      }),
      isEdit: false,
      editIndex: null,
      loadingAltExercise: false,
      altExerciseLoading: null,
    }
  },
  mounted() {
    this.getExerciseCategories()
  },
  methods: {
    ...mapActions({
      getExerciseCategories: 'exerciseCategory/getExerciseCategories',
    }),
    async uploadFile(file) {
      const response = await Api.post('/s3-upload-signed-url/generate', {
        file_name: file.name,
        mime_type: file.type,
        size: file.size,
      })

      const { id, url } = response.data.data

      await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      })

      return id
    },
    async submit() {
      this.submitting = true

      // Simplify the construction of alternative_exercises
      const alternative_exercises = this.form.alternative_exercises.map(
        ({ title, link, thumb_nail_id }) => ({
          title,
          link,
          thumb_nail_id,
        })
      )

      // Initialize payload with properties that don't require conditional logic
      let payload = {
        alternative_exercises,
        categories: this.form.categories,
        content: this.form.content,
        is_free: this.form.is_free,
        title: this.form.title,
      }

      try {
        // Handle video upload or reference
        if (this.form.video_from_s3) {
          payload.video_from_s3 = this.form.video_from_s3
        } else if (this.form.video) {
          const video_id = await this.uploadFile(this.form.video)
          payload = { ...payload, video_id } // Use spread operator to add video_id
        }

        // Handle thumbnail upload or reference
        if (this.form.thumb_nail_from_s3) {
          payload.thumb_nail_from_s3 = this.form.thumb_nail_from_s3
        } else if (this.form.thumbnail) {
          const thumb_nail_id = await this.uploadFile(this.form.thumbnail)
          payload = { ...payload, thumb_nail_id } // Use spread operator to add thumb_nail_id
        }

        const response = await Api.post('/exercise/videos', payload)

        this.form.$reset()
        this.$emit('input', false)

        const { id } = response.data.data

        router.push(`/exercise/${id}`)

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Exercise successfully created.',
        })
      } catch (e) {
        if (e.response?.data?.errors) {
          this.form.$setErrors(e.response?.data?.errors)
        }
      } finally {
        this.submitting = false
      }
    },
    cancelForm() {
      this.form.$reset()
      this.$emit('input', false)
    },
    cancelAltExercise() {
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentExercise()
      this.showAltExerciseForm = false
    },
    resetCurrentExercise() {
      this.editIndex = null
      this.altExerciseForm.blob = null
      this.altExerciseForm.thumb_nail = null
      this.altExerciseForm.$reset()
    },
    async onUpdateAltExercise(altExercise) {
      let thumb_nail_id = null
      try {
        if (altExercise.thumb_nail instanceof File) {
          thumb_nail_id = await this.uploadFile(altExercise.thumb_nail)

          this.form.alternative_exercises[this.editIndex] = {
            title: altExercise.title,
            link: altExercise.link,
            thumb_nail_id: thumb_nail_id,
            thumb_nail: altExercise.thumb_nail,
          }

          this.showAltExerciseForm = false
          this.cancelAltExercise()
        }
      } catch (e) {
        if (e.response?.data?.errors) {
          this.altExerciseForm.$setErrors(e.response?.data?.errors)
        }
      }
    },
    async onAddAltExercise(altExercise) {
      let thumb_nail_id = null

      this.$store.commit('exercise/setAddAltExerciseLoading', true)
      try {
        if (altExercise.thumb_nail instanceof File) {
          thumb_nail_id = await this.uploadFile(altExercise.thumb_nail)

          altExercise.thumb_nail_id = thumb_nail_id
          this.form.alternative_exercises.push({
            title: altExercise.title,
            link: altExercise.link,
            thumb_nail_id: thumb_nail_id,
            thumb_nail: altExercise.thumb_nail,
          })
        }
      } catch (e) {
        if (e.response?.data?.errors) {
          this.altExerciseForm.$setErrors(e.response?.data?.errors)
        }
        return
      }

      this.$store.commit('exercise/setAddAltExerciseLoading', false)
      this.showAltExerciseForm = false
      this.cancelAltExercise()
    },
    onEditAltExercise(altExercise, index) {
      this.isEdit = true
      this.editIndex = index

      this.altExerciseForm.title = altExercise.title
      this.altExerciseForm.link = altExercise.link
      this.altExerciseForm.thumb_nail = altExercise.thumb_nail
      this.altExerciseForm.blob = altExercise.thumb_nail
        ? URL.createObjectURL(altExercise.thumb_nail)
        : null
      this.showAltExerciseForm = true
    },
    async onDeleteAltExercise(altExerciseIndex) {
      this.altExerciseLoading = altExerciseIndex

      this.form.alternative_exercises.splice(altExerciseIndex, 1)

      this.altExerciseLoading = null
    },
  },
}
</script>

<template>
  <div>
    <v-dialog
      v-bind:value="value"
      width="1028"
      v-on:input="(newValue) => $emit('input', newValue)"
      scrollable
    >
      <v-card>
        <v-card-title class="font-semibold text-2xl">
          Create a new exercise
        </v-card-title>

        <v-card-text class="mt-5" style="max-height: 60vh">
          <v-row>
            <v-col cols="9">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model.trim="form.title"
                    :error-messages="form.$getError('title')"
                    class="input__outlined--regular"
                    label="Exercise Title"
                    outlined
                    @input="form.$clearError('title')"
                  />

                  <CategorySelectBox
                    :items="exerciseCategories"
                    :selections="form.categories"
                    @updateSelection="
                      form.categories = $event
                      form.$clearError('categories')
                    "
                    :errors="form.$getError('categories')"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3">
              <v-switch
                v-model="form.is_free"
                label="Free"
                class="mb-8"
                inset
                hide-details
              />
              <thumbnail-uploader
                v-if="!form.thumb_nail_from_s3"
                v-model="form.thumbnail"
                :errors="form.$getError('thumb_nail_id')"
                @input="form.$clearError('thumb_nail_id')"
              />
              <p class="text-center" v-if="!form.thumb_nail_from_s3">or</p>
              <v-text-field
                v-model.trim="form.thumb_nail_from_s3"
                :error-messages="form.$getError('thumb_nail_from_s3')"
                class="input__outlined--regular"
                label="Thumbnail from AWS"
                outlined
                @input="form.$clearError('thumb_nail_from_s3')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <file-uploader
                v-if="!form.video_from_s3"
                v-model="form.video"
                label="Introduction Video"
                accept="video/mp4,video/x-m4v,video/*"
                @input="form.$clearError('video_id')"
                :errors="form.$getError('video_id')?.[0]"
              />
              <span class="error--text ml-3 text-caption">{{
                form.$getError('video_id')?.[0]
              }}</span>
              <p class="text-center" v-if="!form.video_from_s3">or</p>
              <v-text-field
                v-model.trim="form.video_from_s3"
                :error-messages="form.$getError('video_from_s3')"
                class="input__outlined--regular"
                label="Video from AWS"
                outlined
                @input="form.$clearError('video_from_s3')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
                <p class="text-tertiary text-base">Description</p>

                <ContentEditor
                  v-model="form.content"
                  :errors="form.$getError('content')"
                  class="content-editor"
                  @input="form.$clearError('content')"
                />
              </div>
            </v-col>
          </v-row>

          <div class="pt-8" style="border-top: 1px solid #e6e6e9">
            <v-card
              outlined
              class="pa-5"
              :style="{
                border: form.$getError('alternative_exercises')?.[0]
                  ? '2px solid #fa4856'
                  : '',
              }"
            >
              <div class="d-flex justify-space-between align-center mb-3">
                <p>Alternative Exercise</p>
                <v-btn
                  outlined
                  color="primary"
                  large
                  @click="
                    showAltExerciseForm = true
                    form.$clearError('alternative_exercises')
                  "
                >
                  <span class="text-sm font-semibold">Add</span>
                </v-btn>
              </div>

              <v-card
                flat
                outlined
                class="mb-3"
                v-if="form.alternative_exercises?.length > 0"
              >
                <v-list-item-group>
                  <v-list-item
                    v-for="(altExercise, index) in form.alternative_exercises"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        altExercise.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <div>
                      <v-btn
                        class="mr-2"
                        outlined
                        @click="onEditAltExercise(altExercise, index)"
                      >
                        <span class="text-sm">Edit</span>
                      </v-btn>
                      <v-btn
                        outlined
                        @click="onDeleteAltExercise(index)"
                        :loading="altExerciseLoading === altExercise.id"
                      >
                        <span class="text-sm">Delete</span>
                      </v-btn>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-card>
            </v-card>
          </div>
          <span class="error--text ml-3 text-caption">{{
            form.$getError('alternative_exercises')?.[0]
          }}</span>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              :disabled="submitting"
              :loading="submitting"
              block
              color="primary"
              large
              @click="submit"
            >
              <span class="text-sm">Create Exercise</span>
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              :disabled="submitting"
              block
              class="text-sm"
              color="primary"
              large
              outlined
              @click="cancelForm"
            >
              <span class="text-sm">Cancel</span>
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="showAltExerciseForm">
      <AlternativeExerciseForm
        :show="showAltExerciseForm"
        :currentAltExercise="altExerciseForm"
        :isEdit="isEdit"
        :loading="addAltExerciseLoading"
        @onSave="onAddAltExercise"
        @onUpdate="onUpdateAltExercise"
        @onCancel="cancelAltExercise"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-preview,
.video-upload {
  height: 100%;
  border-radius: 8px;
}

.video-upload {
  border: 1px dashed #3b55ce;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-preview {
  background: black;

  &__header {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

#video-preview__content {
  height: 100%;
  width: 100%;
}

.thumbnail-preview {
  height: 4rem;
  width: 4rem;
  border: 1px dashed #3b55ce;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
</style>
