<template>
  <div id="exerciseList">
    <div class="pa-3">
      <v-btn class="text-xs ma-1" text @click="showFilterCategoryModal = true">
        <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
        <span class="text-sm">Add Filter</span>
      </v-btn>
      <v-btn
        class="text-xs ma-1"
        text
        v-if="selectedExercises.length > 0"
        @click="showUpdateCategories = true"
        @loading="updateCategoriesLoading"
      >
        <v-icon class="mr-2">{{ mdiTrendingUp }}</v-icon>
        <span class="text-sm">Update Categories</span>
      </v-btn>
      <v-btn
        @click="showDeleteModalAll = true"
        :loading="deleteAllLoading"
        v-if="selectedExercises.length > 0"
        class="text-xs ma-1"
        text
      >
        <v-icon class="mr-2">{{ mdiTrashCanOutline }}</v-icon>
        <span class="text-sm">Delete all selected</span>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="exerciseList.data.data"
      :options.sync="options"
      @update:options="updateOptions"
      :server-items-length="exerciseList.data.meta.total"
      :loading="exerciseList.loading"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100],
      }"
      v-model="selectedExercises"
      show-select
    >
      <template v-slot:header.title="{ header }">
        <span class="font-medium text-base py-4" style="color: #222222">{{
          header.text
        }}</span>
      </template>

      <template v-slot:header.category="{ header }">
        <span class="font-medium text-base py-4" style="color: #222222">{{
          header.text
        }}</span>
      </template>

      <template v-slot:item.title="{ item }">
        <ExercisePreview :exercise="item" />
      </template>

      <template v-slot:item.is_free="{ item }">
        <p style="color: #222222">{{ item.is_free ? 'Free' : 'Premium' }}</p>
      </template>

      <template v-slot:item.content="{ item }">
        <p class="description-col" style="color: #222222">
          {{ htmlTagParser(item.content) }}
        </p>
      </template>

      <template v-slot:item.category="{ item }">
        <v-chip
          class="ma-1"
          v-for="category in item.main_categories.slice(0, 3)"
          :key="category.id"
          >{{ category.label }}</v-chip
        >
        <span v-if="item.main_categories.length > 5">...</span>
      </template>

      <template v-slot:item.id="{ item }">
        <router-link :to="`/exercise/${item.id}`" class="ma-1">
          <v-btn outlined>
            <span class="text-sm">Edit</span>
          </v-btn>
        </router-link>

        <v-btn
          outlined
          class="text-xs ma-1"
          @click="selectItem(item, 'duplicate')"
          :loading="
            selectedItem?.id === item.id &&
            selectedItemLoading &&
            pendingAction === 'duplicate'
          "
        >
          <span class="text-sm">Duplicate</span>
        </v-btn>

        <v-btn
          outlined
          class="text-xs ma-1"
          @click="selectItem(item, 'delete')"
          :loading="
            selectedItem?.id === item.id &&
            selectedItemLoading &&
            pendingAction === 'delete'
          "
        >
          <span class="text-sm">Delete</span>
        </v-btn>
      </template>
    </v-data-table>

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Duplicate Exercise"
      message="Are you sure you want to duplicate exercise?"
      v-model="showDuplicateModal"
      @cancel="showDuplicateModal = false"
      @confirm="handleDuplicateConfirm"
    />

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Delete Exercise"
      message="This actions is irreversible. <br/> Are you sure you want to delete the exercise ?"
      v-model="showDeleteModal"
      @cancel="showDeleteModal = false"
      @confirm="handleDeleteConfirm"
    />

    <ConfirmModal
      :loading.sync="deleteAllLoading"
      title="Delete Exercises"
      message="This action is irreversible. <br/> Are you sure you want to delete these exercises ?"
      v-model="showDeleteModalAll"
      @cancel="showDeleteModalAll = false"
      @confirm="handleDeleteAllSelected"
    />

    <UpdateCategoryForm
      @update="updateAllExercisesCategories"
      :loading="updateCategoriesLoading"
      v-model="showUpdateCategories"
      ref="updateCategoriesForm"
    />

    <FilterCategory
      v-model="showFilterCategoryModal"
      :filterCategorySelection.sync="filterCategorySelection"
      @update="filterByCategory"
      @updateSelection="filterCategorySelection = $event"
    />
  </div>
</template>

<script>
import Api from '@/services/api'
import { mapActions, mapState } from 'vuex'
import ExercisePreview from './ExercisePreview.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'
import UpdateCategoryForm from '@/components/exercise/category/UpdateCategoryForm.vue'
import FilterCategory from '@/components/exercise/category/FilterCategory.vue'
import { mdiTrashCanOutline, mdiTrendingUp, mdiPlus } from '@mdi/js'

export default {
  name: 'ExerciseList',
  components: {
    ExercisePreview,
    ConfirmModal,
    UpdateCategoryForm,
    FilterCategory,
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['title'],
        sortDesc: [false],
      },
      selectedExercises: [],
      filterCategorySelection: [],
      selectedItem: null,
      pendingAction: '',
      selectedItemLoading: false,
      showDuplicateModal: false,
      showDeleteModal: false,
      showDeleteModalAll: false,
      deleteAllLoading: false,
      showUpdateCategories: false,
      showFilterCategoryModal: false,
      updateCategoriesLoading: false,
      headers: [
        {
          text: 'Exercise',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Access Level',
          align: 'start',
          sortable: false,
          value: 'is_free',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'content',
        },
        {
          text: 'Categories',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'id',
        },
      ],
      mdiTrashCanOutline,
      mdiTrendingUp,
      mdiPlus,
    }
  },
  watch: {
    '$route.query': 'syncOptionsWithRoute',
    options: {
      handler() {
        this.getExercise()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      exerciseList: (state) => state.exercise.exerciseList,
    }),
  },
  created() {
    this.syncOptionsWithRoute()
    this.getExercise()
  },
  methods: {
    ...mapActions({
      getExerciseList: 'exercise/getExerciseList',
    }),
    htmlTagParser(val) {
      return val.replace(/(<([^>]+)>)/gi, '')
    },
    async filterByCategory() {
      this.getExercise()

      this.showFilterCategoryModal = false
    },
    updateOptions(newOptions) {
      this.options = newOptions
      this.updateRoute()
    },
    syncOptionsWithRoute() {
      const query = this.$route.query
      this.options = {
        ...this.options,
        page: query.page ? parseInt(query.page) : 1,
        itemsPerPage: query.itemsPerPage ? parseInt(query.itemsPerPage) : 10,
        sortBy: query.sortBy ? query.sortBy.split(',') : [],
        sortDesc: query.sortDesc
          ? query.sortDesc.split(',').map((v) => v === 'true')
          : [],
      }
    },
    updateRoute() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options
      this.$router.push({
        query: {
          page: page,
          itemsPerPage: itemsPerPage,
          sortBy: sortBy.join(','),
          sortDesc: sortDesc.join(','),
          search: this.$route.query.search ?? '',
        },
      })
    },
    async getExercise() {
      let params = {
        page: this.options?.page ?? 1,
        per_page: this.options?.itemsPerPage ?? 10,
        sort: this.options.sortBy.map((sort, idx) =>
          this.options.sortDesc[idx] ? `-${sort}` : sort
        ),
        search: this.$route.query.search ?? '',
        filter:
          this.filterCategorySelection.length > 0
            ? {
                'filter[category]': this.filterCategorySelection
                  .map((category) => category.id)
                  .join(','),
              }
            : null,
      }

      await this.getExerciseList(params)
    },
    selectItem(exercise, action = '') {
      this.selectedItem = exercise

      if (action === 'duplicate') {
        this.showDuplicateModal = true
      }

      if (action === 'delete') {
        this.showDeleteModal = true
      }
    },
    async handleDuplicateConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'duplicate'

      try {
        await Api.post(`exercise/videos/${this.selectedItem?.id}/duplicate`)
        this.getExercise()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Exercise successfully duplicated.',
        })
      } catch (error) {
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDuplicateModal = false
      }
    },
    async handleDeleteConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'delete'

      try {
        await Api.delete(`exercise/videos/${this.selectedItem?.id}`)
        this.getExercise()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Exercise successfully deleted.',
        })
      } catch (error) {
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDeleteModal = false
      }
    },
    async handleDeleteAllSelected() {
      this.deleteAllLoading = true
      const deletePromises = this.selectedExercises.map((exercise) =>
        Api.delete(`exercise/videos/${exercise.id}`)
      )
      await Promise.all(deletePromises)
      this.selectedExercises = []
      this.deleteAllLoading = false
      this.showDeleteModalAll = false

      this.getExercise()
    },
    async updateAllExercisesCategories(val) {
      this.updateCategoriesLoading = true
      const updatePromise = this.selectedExercises.map((exercise) =>
        Api.post(`exercise/videos/${exercise.id}?_method=put`, {
          categories: val,
        })
      )

      await Promise.all(updatePromise)
      this.selectedExercises = []
      this.$refs.updateCategoriesForm.clearSelectedExercises()
      this.updateCategoriesLoading = false
      this.showUpdateCategories = false

      this.getExercise()
    },
  },
}
</script>

<style lang="scss" scoped>
.description-col {
  width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
