<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title>
        <h3 class="headline font-weight-bold">Exercises</h3>
        <h4 class="poppins-regular font-normal text-sm">
          Add new exercises and manage their categories
        </h4>
      </v-toolbar-title>
    </v-app-bar>

    <div class="pa-4">
      <div class="d-flex justify-space-between align-center">
        <div>
          <v-btn
            outlined
            color="primary"
            large
            class="mr-4"
            @click="openExerciseForm = true"
          >
            <v-icon color="primary">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="text-sm font-semibold">Exercise</span>
          </v-btn>

          <v-btn
            outlined
            color="primary"
            large
            class="mr-4"
            :loading="uploadExercisesLoading"
            @click="uploadFile"
          >
            <v-icon color="primary">
              {{ icons.mdiFileUpload }}
            </v-icon>
            <span class="text-sm font-semibold">Upload Exercises</span>
          </v-btn>

          <v-btn
            outlined
            color="primary"
            large
            @click="showCategoryCreateModal = true"
          >
            <v-icon color="primary">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="text-sm font-semibold">Category</span>
          </v-btn>
        </div>

        <div style="min-width: 16rem">
          <v-text-field
            class="input__outlined--regular text-sm w-100"
            label="Search"
            outlined
            hide-details
            v-model.trim.lazy="search"
            @input="refreshList"
          />
        </div>
      </div>

      <div class="exercise-page-content">
        <v-tabs
          v-model="tab"
          style="
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
          "
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            @click="changeTab"
            v-for="tab in ['Exercises', 'Categories']"
            :key="`tab-header-${tab}`"
            class="font-medium text-base normal-case"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="border-radius: 8px">
          <v-tab-item key="Exercises">
            <exercise-list />
          </v-tab-item>

          <v-tab-item key="Categories">
            <category-list />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <ExerciseFormModal v-model="openExerciseForm" />

    <CategoryForm
      :show="showCategoryCreateModal"
      :form="categoryform"
      :loading="categoryLoading"
      @onCancel="closeFormModal()"
      @onSave="addCategory()"
    />

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
    ></MediaUploader>
  </div>
</template>
<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlus, mdiFileUpload } from '@mdi/js'
import ExerciseList from '@/components/exercise/ExerciseList.vue'
import CategoryList from '@/components/exercise/category/CategoryList.vue'
import ExerciseFormModal from '@/components/exercise/ExerciseFormModal.vue'
import MediaUploader from '@/components/MediaUploader'
import CategoryForm from '@/components/exercise/CategoryForm'
import { mapActions, mapMutations, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'ExerciseIndex',
  components: {
    AppBarNavIcon,
    ExerciseList,
    ExerciseFormModal,
    MediaUploader,
    CategoryList,
    CategoryForm,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiFileUpload,
      },
      tab: 'Exercises',
      openExerciseForm: false,
      openCategoryForm: false,
      search: '',
      showCategoryCreateModal: false,
    }
  },
  computed: {
    ...mapState({
      categoryform: (state) => state.exerciseCategory.categoryForm,
      exerciseList: (state) => state.exercise.exerciseList,
      exerciseCategoryList: (state) => state.exerciseCategory.categoryList,
      categoryLoading: (state) => state.exerciseCategory.categoryLoading,
      uploadExercisesLoading: (state) => state.exercise.uploadExercisesLoading,
    }),
  },
  created() {
    this.search = this.$route.query.search || ''
  },
  methods: {
    ...mapActions({
      getExerciseList: 'exercise/getExerciseList',
      getExerciseCategoryList: 'exerciseCategory/getExerciseCategoryList',
      uploadExercises: 'exercise/uploadExercises',
      addExerciseCategory: 'exerciseCategory/addExerciseCategory',
    }),
    ...mapMutations({
      resetCategoryFormError: 'exerciseCategory/resetCategoryFormError',
    }),
    changeTab() {
      this.search = ''
      this.$router.replace({ query: null })
      this.refreshList()
    },

    refreshList: debounce(async function (search) {
      if (this.tab === 0) {
        const { params } = this.exerciseList

        await this.getExerciseList({ ...params, search })
      }

      if (this.tab === 1) {
        const { params } = this.exerciseCategoryList

        await this.getExerciseCategoryList({ ...params, search })
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          search: search,
        },
      })
    }, 500),
    uploadFile() {
      this.$refs.triggerUpload.openFile()
    },
    onMediaLoad(data) {
      this.uploadExercises(data.file)
    },
    closeFormModal() {
      this.showCategoryCreateModal = false
      this.resetCategoryFormError()
    },
    addCategory() {
      this.addExerciseCategory(this.categoryform).then((res) => {
        if (!this.categoryform.has_error) {
          this.showCategoryCreateModal = false
        }
      })
    },
  },
}
</script>

<style>
.exercise-page-content {
  margin-top: 2rem;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
}
</style>
