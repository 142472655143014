<template>
  <div>
    <div
      class="thumbnail-uploader"
      :class="{
        'thumbnail-uploader__dashed': !!!previewUrl,
        'thumbnail-uploader__errors': hasErrors,
      }"
    >
      <v-responsive :aspect-ratio="4 / 3">
        <div
          class="d-flex justify-center flex-column align-center thumbnail-uploader__content"
        >
          <template v-if="previewUrl">
            <v-img
              cover
              :src="previewUrl"
              :aspect-ratio="4 / 3"
              style="height: 100%; width: 100%; border-radius: 8px"
            />
          </template>

          <template v-else>
            <div>
              <v-img src="@/assets/icons/gallery.svg" width="40" height="40" />
            </div>

            <div class="mt-4">
              <v-btn
                color="primary"
                @click="$refs[`thumbnail-uploader-${id}`].click()"
              >
                <span class="text-sm">Upload Photo</span>
              </v-btn>
            </div>
          </template>
        </div>
      </v-responsive>
    </div>

    <input
      :ref="`thumbnail-uploader-${id}`"
      class="d-none"
      type="file"
      @input="onInput"
      accept="image/*"
    />

    <p
      v-if="previewUrl"
      class="text-sm text-primary font-semibold text-center my-4 clickable"
      @click="$refs[`thumbnail-uploader-${id}`].click()"
    >
      Change Thumbnail
    </p>

    <div class="v-text-field__details mt-2 ml-3" v-if="errors.length">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div
            v-for="error in errors"
            :key="`content-errors-${error}`"
            class="v-messages__message text-center"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailUploader',
  props: {
    id: String,
    media: {
      type: Object,
      default: () => {},
    },
    blob: {
      type: [File, Object],
      default: () => null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      previewUrl: this.media?.thumb_url || this.blob || '',
    }
  },
  computed: {
    hasErrors() {
      return !!this.errors.length
    },
  },
  methods: {
    onInput(e) {
      if (e.target.files.length === 0) return

      const [file] = e.target.files

      this.$emit('input', file)

      this.previewUrl = URL.createObjectURL(file)
    },
  },
}
</script>

<style lang="scss">
.thumbnail-uploader {
  border-radius: 8px;

  &__content {
    height: 100%;
  }

  &__dashed {
    border: 2px dashed rgba(59, 85, 206, 0.8) !important;
  }

  &__errors {
    border: 2px dashed #fa4856 !important;
  }
}
</style>
