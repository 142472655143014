<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="font-semibold text-2xl"
          >{{ capitalizeFirstLetter(action) }} Alternative Exercise</span
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="6">
            <thumbnail-uploader
              v-model="currentAltExerciseHolder.thumb_nail"
              :media="currentAltExerciseHolder.thumb_nail"
              :blob="currentAltExerciseHolder.blob"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              required
              label="Title"
              class="mb-7"
              v-model="currentAltExerciseHolder.title"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              outlined
              required
              label="Link"
              v-model="currentAltExerciseHolder.link"
              :rules="[rules.link]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <v-btn
            :disabled="!checkDisabled"
            :loading="loading"
            block
            color="primary"
            large
            @click="isEdit ? formActions('update') : formActions('save')"
          >
            {{ `${isEdit ? 'Update' : 'Add'}` }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            block
            class="text-sm"
            color="primary"
            large
            outlined
            @click="formActions('cancel')"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
    ></MediaUploader>
  </v-dialog>
</template>

<script>
import MediaUploader from '@/components/MediaUploader'
import ThumbnailUploader from '@/components/base/ThumbnailUploader'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: 'Add',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentAltExercise: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentAltExerciseHolder: { ...this.currentAltExercise },
      rules: {
        link: (value) => {
          const pattern =
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/
          return pattern.test(value) || 'Invalid Link'
        },
      },
    }
  },
  computed: {
    checkDisabled() {
      const pattern =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/
      return (
        this.currentAltExerciseHolder.title &&
        this.currentAltExerciseHolder.link &&
        pattern.test(this.currentAltExerciseHolder.link) &&
        this.currentAltExerciseHolder.thumb_nail
      )
    },
  },
  mounted() {
    this.currentAltExerciseHolder = { ...this.currentAltExercise }
  },
  components: {
    MediaUploader,
    ThumbnailUploader,
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formActions(action) {
      if (action === 'update') {
        this.$emit('onUpdate', this.currentAltExerciseHolder)
      } else if (action === 'save') {
        this.$emit('onSave', this.currentAltExerciseHolder)
      } else {
        this.$emit('onCancel')
      }
    },
    uploadThumbnail() {
      this.$refs.triggerUpload.openFile()
    },
    checkThumbNail(currentAltExerciseHolder) {
      return (
        currentAltExerciseHolder?.thumb_nail?.[0]?.url ||
        currentAltExerciseHolder?.thumb_nail?.url
      )
    },
    onMediaLoad(data) {
      this.currentAltExerciseHolder.thumb_nail = [
        {
          file: data.file.get('file'),
          url: data.url,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped></style>
