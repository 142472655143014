<template>
  <div class="preview">
    <div>
      <v-img
        :lazy-src="exercise.thumb_nail?.thumb_url"
        height="56"
        width="80"
        :src="exercise.thumb_nail?.url"
        class="rounded-lg"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>

    <div class="preview__summary">
      <p class="font-medium text-sm mb-0">{{ exercise.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramCard',
  props: {
    exercise: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss">
.preview {
  display: flex;

  &__summary {
    margin-left: 1rem;
    flex: 1;
    color: #222222;

    &__subtitle {
      opacity: 0.5;
    }
  }
}
</style>
