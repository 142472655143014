<style scoped></style>

<template>
  <div class="media-uploader">
    <input
      v-show="false"
      :ref="'file-input'"
      type="file"
      :accept="`/${mediaType}*`"
      :multiple="!singleMedia"
      @change="initMediaToUpload"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'MediaUploader',
  props: {
    singleMedia: {
      type: Boolean,
      default: true,
    },
    mediaType: {
      type: String,
      default: 'image',
    },
  },
  data() {
    return {
      imageHolder: [],
    }
  },
  methods: {
    initMediaToUpload(e) {
      if (this.mediaType === 'video') {
        this.$emit('startUpload', true)
      }
      const files = e.target.files || e.dataTransfer.files
      const formData = new FormData()

      files.forEach((file) => {
        formData.append('file', file)
      })

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const file = files[i]

        reader.readAsDataURL(file)

        const on = this
        const thumb = URL.createObjectURL(file)
        reader.onload = function (e) {
          const finalThumb = e.target.result !== '' ? e.target.result : thumb
          on.mediaFile({ file: formData, url: finalThumb })
        }

        reader.onprogress = function (data) {
          if (data.lengthComputable) {
            let progress = parseInt((data.loaded / data.total) * 100, 10)
            on.setStateProgress(progress)
          }
        }
      }
    },

    openFile() {
      this.$refs['file-input'].click()
    },

    mediaFile(data) {
      this.$emit('onMediaLoad', data)
      this.$refs['file-input'].value = null
    },

    ...mapMutations({
      setStateProgress: 'setStateProgress',
    }),
  },
}
</script>
