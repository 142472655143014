<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="500">
    <v-card>
      <v-card-title class="headline font-weight-bold">
        {{ title }}
      </v-card-title>
      <v-card-text class="poppins-regular font-normal text-sm">
        <div v-html="message" />
      </v-card-text>
      <v-card-actions>
        <slot>
          <v-col>
            <v-btn
              :disabled="loading"
              block
              class="text-sm"
              color="primary"
              large
              outlined
              @click="$emit('cancel')"
            >
              <span class="text-sm">Cancel</span>
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              :disabled="loading"
              :loading="loading"
              block
              color="primary"
              large
              @click="$emit('confirm')"
            >
              <span class="text-sm">Confirm</span>
            </v-btn>
          </v-col>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
